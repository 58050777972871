<template>
  <div>
    <div class="flex flex-col items-center justify-center">
      <p class="font-bold text-xl">Вы готовы пожертвовать свободным
        временем сейчас, чтобы изменить уровень <br>
        своего дохода в будущем?</p>
      <div class="pt-10 xl:w-1/2">
        <div class="center content-inputs my-4">
          <div class="ratio my-2" v-for="item in questions" :key="item" :id="item">
            <label class="text-xl">
              <vs-radio class="py-2" v-model="value.desire" :val="item">
                {{ item }}
              </vs-radio>
            </label>
          </div>
        </div>
      </div>
      <div class="pt-10">
        <vs-button :color=data.color @click="send()" class="my-5 p-2 xl:mx-0 shadow-xl">
          <h4>Далее</h4>
        </vs-button>
      </div>
    </div>
    <h4 class="my-2 text-red-500" v-if="error">Проверьте правильность ввденых данных</h4>
  </div>
</template>

<script>
import {TestMixin} from "../../mixin/test";
import {StepMixin} from "./mixin";

export default {
  name: "Step5",
  mixins: [TestMixin, StepMixin],
  props: {step: {default: 0}},

  data: function () {
    return {
      fields: ["desire",],
      questions: ['да, у меня есть желание работать и обучаться тому, что гарантированно увеличит мой доход.',
        'у меня нет желания работать и обучаться, умею довольствоваться малым.']
    }
  }
}
</script>

<style scoped>
.vs-radio-content {
  justify-content: start;
}
</style>