<template>
  <div class="flex justify-center xl:bg-gray-50 h-screen">
    <div class="max-w">
      <div v-if="!loading.isVisible" class="flex flex-row flex-wrap xl:h-screen	items-center relative">
        <div class="xl:w-6/12 w-full h-48 md:h-64 dash_img">
          <div class="h-full bg-cover bg-center"
               :style="{'background-image': `url(${data.image})`}"></div>
        </div>
        <div class="xl:absolute px-0 xl:px-10 xl:w-6/12 2xl:w-5/12 items-center z-20 right-20 2xl:right-60">

          <div class="px-10 py-10 xl:shadow-2xl xl:rounded-xl bg-white">

            <h3>{{ data.title }}</h3>
            <h4 class="pt-5 font-light text-gray-500"
                v-html="data.description.replace(/(?:\r\n|\r|\n)/g, '<br />')"></h4>
            <div class="flex flex-col justify-center">
              <vs-button :color=data.color to="/question" class="my-5 p-2 xl:mx-0 shadow-xl">
                <h4>Попробовать</h4>
              </vs-button>
              <bonus v-if="data.bonus" :color="data.color"></bonus>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Bonus from "@/components/Bonus";
import {TestMixin} from "@/mixin/test";

export default {
  name: "Home",
  mixins: [TestMixin,],

  components: {Bonus},
  data: function () {
    return {
      update_data: true,
      data: {color: "#000", image: null, title: "", description: "about product"},
    }
  },
}
</script>

<style lang="scss">

@import "~@/assets/scss/home";

</style>
