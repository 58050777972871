<template>
  <div class="flex justify-center xl:h-screen items-center">
    <div v-if="!loading.isVisible"
         class="max-w flex 2xl:flex-row flex-col rounded-xl md:shadow-xl bg-white md:m-10 p-5  justify-between items-center">
      <div class="relative w-full">
        <component v-on:next_step="next_step" :step="step" :is="`Step${this.step}`"></component>
      </div>
      <div v-if="data.bonus">
        <bonus :color="data.color" class="justify-center px-10" :val="get_bonus(data.bonus)"></bonus>
      </div>
    </div>
  </div>
</template>

<script>
import Bonus from "@/components/Bonus";
import QProgress from "@/components/QProgress";
import * as components from '@/components/quiz'
import {TestMixin} from "@/mixin/test";
import {QuestionMixin} from "@/mixin/questions";

export default {
  name: "Question",
  components: {QProgress, Bonus, ...components},
  mixins: [TestMixin, QuestionMixin],

  methods: {
    next_step() {
      this.step += 1;
    },
  },
  data: function () {
    setTimeout(() => this.disable = true, 1000)
    return {
      step: 1,
      disable: false,
      update_data: true,
    }
  }
}
</script>

<style scoped lang="scss">

@import "~@/assets/scss/bonus";
@import "~@/assets/scss/question";

</style>
