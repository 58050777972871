import {get_quiz} from "@/mixin/request";

export const TestMixin = {
    created() {
        this.getData()
    },

    data() {
        return {
            data: {
                profit: 0,
                answer: [],
                color: '#000',
                quiz: {},
                video_platform: "",
                about_project: ""
            },
            update_data: false,
            loading: this.$vs.loading({
                background: '#fff',
                color: '#000'
            })
        }
    },

    methods: {
        getYoutubeUrl(url) {
            let regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/;
            let match = url.match(regExp);
            return (match && match[7].length == 11) ? match[7] : false;
        },

        getData() {
            get_quiz(this.update_data).then(
                data => {
                    if (data) {
                        this.data = data
                        this.color = data.color
                        document.title = data.title
                        this.loading.close()
                    }
                }
            ).catch(() => (setTimeout(this.getData, 5000))
            )
        }
    }
}