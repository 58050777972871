<template>
  <div class="flex justify-center bg-gray-50 h-screen">
    <div v-if="!loading.isVisible" class="max-w">
      <div class="flex flex-row flex-wrap xl:h-screen	items-center relative">
        <div class="xl:w-6/12 w-full h-48 md:h-64 dash_img">
          <div class="h-full bg-cover bg-center"
               :style="{'background-image': `url(${data.image})`}"></div>
        </div>
        <div class="xl:absolute px-0 xl:w-8/12 items-center z-20 right-0">
          <div class="p-10 xl:shadow-2xl xl:rounded-xl bg-white border">
            <div class="flex flex-col xl:grid xl:grid-cols-2 grid-cols-1 justify-center items-center">
              <div class="mb-5 justify-center flex flex-col">
                <h3 class="whitespace-pre-wrap" v-text="data.finish_title">
                </h3>
                <p class="pt-10 text-xl" v-text="data.finish_description"></p>
              </div>
              <div class="xl:pl-10">
                <p class="font-bold text-center">Выбери удобный способ связи для получения системы “4 шага”</p>
                <div class="center content-inputs my-10">
                  <div class="ratio my-2" v-for="(item, key) in data.social_after" :key="item" :id="item">
                    <label class="text-xl">
                      <vs-radio class="py-2" v-model="value.social" :val="key">
                        {{ key }}
                      </vs-radio>
                    </label>
                  </div>
                </div>
                <div class="flex flex-col">
                  <vs-button :color=data.color @click="send()" :active-disabled="!value.social"
                             class="p-2 xl:mx-0 shadow-xl">
                    <h4>Запустить систему "4 шага"</h4>
                  </vs-button>
                  <bonus v-if="data.bonus" :val="data.bonus" :color="data.color"></bonus>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Bonus from "@/components/Bonus";
import {request} from "@/mixin/request";
import {TestMixin} from "../mixin/test";
import {StepMixin} from "../components/quiz/mixin";

export default {
  name: "Form",
  components: {Bonus},
  mixins: [TestMixin, StepMixin],

  created() {
    let answer = localStorage.getItem("answer")
    if (!answer || !this.userId) {
      this.$router.push('/')
    } else {
      Object.keys(JSON.parse(answer)).forEach((val) => {
        if (!(['name', 'age', 'theme', 'planed_profit', 'current_profit', 'desire'].includes(val))) {
          this.$router.push('/')
        }
      })
    }
  },

  methods: {
    send() {
      if (!this.value.social) {

        return
      }
      request().post(`/test/update/${this.userId}/`, {
        answers: this.value,
        current_step: 10
      }).then(
          () => {
            localStorage.clear()
            window.location.href = this.data.social_after[this.value.social]
          }
      ).catch(() => {
            alert('Неопознаная ошибка')
          }
      )
    }
  },

  data() {
    return {
      userId: localStorage.getItem("userId"),
      data: {color: "#000", bonus: 0, image: null, text_form: "", link_after: "/"},
      errors: {name: null, phone: null, email: null}
    }
  }
}
</script>

<style lang="scss">

.vs-input {
  width: 100%;
}

</style>
