<template>
  <div>
    <div class="flex flex-col items-center justify-center">
      <div>
        <label class="font-bold text-xl">Есть платформа, где с помощью интернета и
          двух приложений можно начать зарабатывать
          имея в руках только смартфон.
          <br>
          <br>
          Если вы узнаете, что есть алгоритм работы, в
          котором доход растет от действий, обучения
          и времени.
          <br>
          <br>
          Какой подход к работе вы бы выбрали? </label>
      </div>
      <div class="pt-10">
        <div class="center content-inputs my-4">
          <div class="ratio" v-for="item in data.answer" :key="item" :id="item">
            <label class="text-xl">
              <vs-radio class="py-2" v-model="value.planed_profit" :val="item">
                {{ item }}
              </vs-radio>
            </label>
          </div>
        </div>
      </div>
      <div class="pt-10">
        <vs-button :color=data.color @click="send()" class="my-5 p-2 xl:mx-0 shadow-xl">
          <h4>Далее</h4>
        </vs-button>
      </div>
    </div>
    <h4 class="my-2 text-red-500" v-if="error">Проверьте правильность ввденых данных</h4>
  </div>
</template>

<script>
import {TestMixin} from "../../mixin/test";
import {StepMixin} from "./mixin";

export default {
  name: "Step3",
  mixins: [TestMixin, StepMixin],
  props: {step: {default: 0}},

  data() {
    return {
      fields: ["planed_profit"]
    }
  }
}
</script>

<style scoped>
.vs-radio-content {
  justify-content: start;
}
</style>