<template>
  <div>
    <div class="items-center justify-center flex flex-col content-inputs my-4">
      <div>
        <h2>{{ value.name }}, в какой теме вы больше видите себя</h2>
      </div>

      <div class="my-10">
        <div class="ratio py-2 flex flex-col" v-for="(item, index) in data.quiz" :key="item"
             :id="index">
          <label class="text-xl">
            <vs-radio v-model="value.theme" :val="index" @click="error=false">
              {{ index }}
            </vs-radio>
          </label>
        </div>
      </div>
      <div>
        <vs-button :color=data.color @click="send()" class="my-5 p-2 xl:mx-0 shadow-xl">
          <h4>Далее</h4>
        </vs-button>
      </div>
      <h4 class="my-2 text-red-500" v-if="error">Проверьте правильность ввденых данных</h4>
    </div>
  </div>
</template>

<script>
import {TestMixin} from "../../mixin/test";
import {StepMixin} from "./mixin";

export default {
  name: "Step2",
  mixins: [TestMixin, StepMixin],
  props: {step: {default: 0}},

  data() {
    return {
      fields: ["theme"]
    }
  }
}
</script>

<style scoped>
.vs-radio-content {
  justify-content: start;
}
</style>