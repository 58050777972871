<template>
  <div>
    <div class="flex flex-col items-center justify-center">
      <div>
        <h3 class="font-bold text-2xl">Подробнее о проекте</h3>
      </div>
      <div class="pt-10 xl:w-2/3 ">
        <p style="white-space: pre-line;" class="text-xl">{{ data.about_detail_project }}</p>
        <p class="text-xl font-bold pt-10 text-center">
          {{ value.name }}, мы подготовили для Вас простую 4-х шаговую систему запуска, чтобы вы начали зарабатывать уже
          сегодня. Жмите на кнопку:
          “Получить систему 4 шага”
        </p>
      </div>
      <div class="pt-10">
        <vs-button :color=data.color to="/form" class="my-5 p-2 xl:mx-0 shadow-xl">
          <h4>Получить систему 4 шага</h4>
        </vs-button>
      </div>
      <div class="pt-5">
        <p class="font-bold text-xl text-center">
          Напишите <br>
          куратору проекта<br>
          <br>
          "Интересует заработок на платформе"
        </p>
      </div>
    </div>
    <h4 class="my-2 text-red-500" v-if="error">Проверьте правильность ввденых данных</h4>
  </div>
</template>

<script>
import {TestMixin} from "../../mixin/test";
import {StepMixin} from "./mixin";

export default {
  name: "Step8",
  mixins: [TestMixin, StepMixin],
  props: {step: {default: 0}},
}
</script>

<style scoped>
.vs-radio-content {
  justify-content: start;
}
</style>