<template>
  <div>
    <div class="flex flex-col items-center justify-center">
      <h3 class="font-bold text-2xl">Подробнее о проекте</h3>
      <div class="pt-10">

        <iframe class="embedded-video-16-9"
                :src="value.theme ? `https://www.youtube.com/embed/${getYoutubeUrl(data.quiz[value.theme])}` : ''">
        </iframe>
      </div>
      <div class="pt-10">
        <vs-button :color=data.color @click="send()" :disabled="disable" class="my-5 p-2 xl:mx-0 shadow-xl">
          <h4>Далее</h4>
        </vs-button>
      </div>
    </div>
  </div>
</template>

<script>
import {TestMixin} from "../../mixin/test";
import {StepMixin} from "./mixin";

export default {
  name: "Step7",
  mixins: [TestMixin, StepMixin],
  props: {step: {default: 0}},
  data() {
    setTimeout(() => this.disable = false, 5000)
    return {
      disable: true,
    }
  }
}
</script>

<style scoped>
.embedded-video-16-9 {
  width: 90vw;
  height: 50.625vw; /* 90*9/16 */
  margin-left: 5vw;
  margin-right: 5vw;
}

@media (min-width: 893px) {
  .embedded-video-16-9 {
    width: 45vw;
    height: 25.3125vw; /* 45*9/16 */
    margin-left: 2vw;
    margin-right: 2vw
  }
}
</style>