<template>
  <div>
    <div class="items-center justify-center flex flex-col content-inputs my-4">
      <div>
        <h2>Давайте знакомится</h2>
        <div class="form my-10">
          <label class="text-xl">Ваше имя</label>
          <vs-input
              @click="error=false"
              v-model="value.name"
              placeholder="Введите ваше имя"
          />
        </div>
        <div class="center content-inputs mt-10">
          <label class="text-xl">Ваш возраст</label>
          <vs-input
              type="number"
              @click="error=false"
              v-model="value.age"
              placeholder="Введите ваш возраст"
          />
        </div>
        <div class="mt-10">
          <div class="flex justify-center items-center w-full">
            <div>
              <vs-button :color=data.color @click="createUser" class="my-5 p-2 xl:mx-0 shadow-xl">
                <h4>Далее</h4>
              </vs-button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <h4 class="my-2 text-red-500" v-if="error">Проверьте правильность ввденых данных</h4>
  </div>
</template>

<script>
import {TestMixin} from "../../mixin/test";
import {StepMixin} from "./mixin";
import {request} from "../../mixin/request";

export default {
  name: "Step1",
  mixins: [TestMixin, StepMixin],
  props: {step: {default: 0}},
  data() {
    return {
      fields: ["name", "age"]
    }
  },
  methods: {
    createUser() {
      const requestData = {
        test: this.data.id,
        answers: {},
        current_step: 0,
        name: this.value.name,
        age: this.value.age
      }
      if (!this.userId) {
        request().post("/test/create/", requestData).then(
            (resp) => {
              localStorage.setItem('userId', resp.data.id)
              this.send()
            }
        ).catch(() => {
              alert('Ошибка сети')
            }
        )
      } else {
        this.send()
      }
    }
  }
}
</script>

<style scoped>

</style>