<template>
  <div>
    <div class="flex flex-col items-center justify-center">
      <div>
        <h3 class="font-bold text-2xl">Подробнее о проекте</h3>
      </div>
      <div class="pt-10">
        <p style="white-space: pre-line;" class="text-xl">{{ data.about_project }}</p>
      </div>
      <div class="pt-10">
        <vs-button :color=data.color @click="send()" class="my-5 p-2 xl:mx-0 shadow-xl">
          <h4>Далее</h4>
        </vs-button>
      </div>
    </div>
    <h4 class="my-2 text-red-500" v-if="error">Проверьте правильность ввденых данных</h4>
  </div>
</template>

<script>
import {TestMixin} from "../../mixin/test";
import {StepMixin} from "./mixin";

export default {
  name: "Step8",
  mixins: [TestMixin, StepMixin],
  props: {step: {default: 0}},
}
</script>

<style scoped>
.vs-radio-content {
  justify-content: start;
}
</style>