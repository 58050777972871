import axios from "axios";


export function request() {
    let baseUrl = process.env.NODE_ENV === "production" ? "/api/v1" : "http://0.0.0.0:8017/api/v1"
    return axios.create({
        baseURL: baseUrl,
        headers: {
            accept: "application/json",
            "Content-Type": "application/json"
        }
    });
}


export async function get_quiz(update = false) {
    if (!update) {
        let response = localStorage.getItem("test")
        if (response) {
            return JSON.parse(response)
        }
    }

    return request().get("/test/").then(
        response => {
            localStorage.setItem("test", JSON.stringify(response.data))
            return response.data
        }
    )
}