<template>
  <div>
    <div class="flex flex-col items-center justify-center">
      <div>
        <h3>Выберите ваш текущий доход в месяц </h3>
      </div>
      <div class="pt-10">
        <div class="center content-inputs my-4">
          <div class="ratio my-2" v-for="item in data.profit" :key="item" :id="item">
            <label class="text-xl">
              <vs-radio class="py-2" v-model="value.current_profit" :val="`${item} ${data.profit}`">
                {{ item }}
              </vs-radio>
            </label>
          </div>
        </div>
      </div>
      <div class="pt-10">
        <vs-button :color=data.color @click="send()" class="my-5 p-2 xl:mx-0 shadow-xl">
          <h4>Далее</h4>
        </vs-button>
      </div>
    </div>
    <h4 class="my-2 text-red-500" v-if="error">Проверьте правильность ввденых данных</h4>
  </div>
</template>

<script>
import {TestMixin} from "../../mixin/test";
import {StepMixin} from "./mixin";

export default {
  name: "Step4",
  mixins: [TestMixin, StepMixin],
  props: {step: {default: 0}},

  data: function () {
    return {
      fields: ["current_profit",],
      questions: ['меньше', 'больше'],
      current_profit: ["current_profit"]
    }
  }
}
</script>

<style scoped>
.vs-radio-content {
  justify-content: start;
}
</style>