import {request} from "../../mixin/request";


export const StepMixin = {
    data() {
        return {
            error: null,
            is_next: false,
            userId: localStorage.getItem("userId"),
            fields: [],
            value: localStorage.getItem("answer") ? JSON.parse(localStorage.getItem("answer")) : {
                name: null,
                age: null,
                planed_profit: null,
                theme: null,
                current_profit: null,
                desire: null
            },
        }
    },


    methods: {
        send: function () {
            for (const name of this.fields) {
                if (!this.value[name]) {
                    this.error = true
                    return
                }
            }
            this.error = false
            if (this.step > 1) {
                request().post(`/test/update/${this.userId}/`, {
                    answers: this.value,
                    current_step: this.step
                })
            }

            if (!this.error) {
                localStorage.setItem("answer", JSON.stringify(this.value))
                this.$emit('next_step')
            }
        }
    }
}